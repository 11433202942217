export function checkHour(date) {
  var seconds = Math.floor((new Date() - (date * 1000)) / 1000);
  var interval = seconds / 31536000;

  interval = seconds / 3600;

  return interval;
};

export function trunc(address) {
  return address.substr(0, 6) + '...' + address.substr(address.length - 4, address.length);
};

export function timeSince(date) {
  var seconds = Math.floor((new Date() - (date * 1000)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y ago";
  };

  interval = seconds / 2592000;

  if (interval > 1) {
    return Math.floor(interval) + "mo ago";
  };

  interval = seconds / 86400;

  if (interval > 1) {
    return Math.floor(interval) + "d ago";
  };

  interval = seconds / 3600;

  if (interval > 1) {
    return Math.floor(interval) + "h ago";
  };

  interval = seconds / 60;

  if (interval > 1) {
    return Math.floor(interval) + "m ago";
  };

  return Math.floor(seconds) + "s ago";
};

export const numberWithCommas = (x) => {
  const [integerPart, fractionalPart] = x.toString().split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return fractionalPart ? `${formattedIntegerPart}.${fractionalPart}` : formattedIntegerPart;
};

export const convertMicrotokensToTokens = (microtokens) => {
  const tokens = microtokens / 1000000;
  return tokens;
};

export const formatTokens = (input, decimalPlaces = 2, allowNegative = true) => {
  let tokens = typeof input === 'string' ? parseFloat(input) : input;

  if (typeof tokens !== 'number' || isNaN(tokens) || !isFinite(tokens)) {
    return 0;
  };

  if (tokens < 0 && !allowNegative) {
    return 0;
  };

  const [integerPart, fractionalPart] = tokens.toPrecision(12).toString().split('.');
  let truncatedFractionalPart = fractionalPart ? fractionalPart.slice(0, decimalPlaces) : '';

  const truncatedTokens = `${integerPart}.${truncatedFractionalPart}`;

  return parseFloat(truncatedTokens);
};

export const formatTokensDisplay = (input, decimalPlaces = 2, allowNegative = true) => {
  if (input === 0 || input === null || input === '') {
    return '0.00';
  };

  if (input < 0 && !allowNegative) {
    return '0.00';
  };

  const formattedTokens = formatTokens(input, decimalPlaces);
  const [integerPart, fractionalPart] = formattedTokens.toString().split('.');
  const truncatedFractionalPart = fractionalPart ? fractionalPart.padEnd(decimalPlaces, '0') : '00';

  let finalFormattedTokens = `${integerPart}.${truncatedFractionalPart}`;
  finalFormattedTokens = finalFormattedTokens.replace(/(\.\d{2}\d*?)0+$/, '$1');

  return numberWithCommas(finalFormattedTokens);
};