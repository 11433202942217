import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { trunc } from "../lib/utils.js";

export default function HeaderMenuPopupComponent({show, toggleMenu, showApi}) {
  const [visibleClass, setVisibleClass] = useState("");
  const [theme, setTheme] = useState("light-mode");
  const [api, setApi] = useState("Hiro");
  const navigate = useNavigate();

  const menuRef = useRef();

  useEffect(() => {
    if (show) {
      setVisibleClass('visible');
    } else {
      setVisibleClass('');
    };
  }, [show]);

  useEffect(() => {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if (storedPreferences.theme) {
        setTheme(storedPreferences.theme);
      };

      if (storedPreferences.api) {
        if (storedPreferences.api === "https://api.hiro.so") {
          setApi("Hiro");
        } else if (storedPreferences.api === "https://stacks-blockchain-lb.alexlab.co") {
          setApi("ALEX");
        } else {
          setApi("Custom");
        };
      };
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        toggleMenu();
      };
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  function toggleTheme() {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if(storedPreferences.theme === "light-mode") {
        storedPreferences.theme = "dark-mode";
        setTheme("dark-mode");
      } else if(storedPreferences.theme === "dark-mode") {
        storedPreferences.theme = "light-mode";
        setTheme("light-mode");
      };
      localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
      document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
    };
  };

  return (
    <>
    <div className={`headerMenu ${visibleClass}`} ref={menuRef}>
      <div onClick={() => { navigate('/'); toggleMenu(); }} className="headerOnMobile">
        <h1><NavLink to="/" className="navLink">Home</NavLink></h1>
      </div>
      <div onClick={() => { navigate('/discover'); toggleMenu(); }} className="headerOnMobile">
        <h1><NavLink to="/discover" className="navLink">Discover</NavLink></h1>
      </div>
      <div className="divider headerOnMobile"></div>
      <div onClick={() => { showApi(); toggleMenu(); }}>
        <h1>API Provider</h1>
        <h2>{api}</h2>
      </div>
      <div onClick={toggleTheme}>
        <h1>Dark Mode</h1>
        <h2>{theme === "dark-mode" ? "On" : "Off"}</h2>
      </div>
      <div className="divider"></div>
      <div onClick={() => { window.location.href = "https://app.uwu.cash"; }}>
        <h1>Go to Web App</h1>
      </div>
    </div>
    </>
  );
};