import React, { useState, useEffect } from "react";
import { fetchSession } from "./lib/api.js";
import "./styles/globals.css";
import { Outlet } from "react-router-dom";

import HeaderComponent from "./components/HeaderComponent.js";
import FooterComponent from "./components/FooterComponent.js";

function App() {

  const [session, setSession] = useState();

  useEffect(() => {
    fetchSession().then(res => setSession(res));
  }, []);

  useEffect(() => {
    fetchSession().then(res => setSession(res));

    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if (storedPreferences) {
      if (storedPreferences.theme) {
        document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
        document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
      } else {
        storedPreferences.theme = "light-mode";
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
        document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
        document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
      };
    } else {
      localStorage.setItem("uwu-preferences", JSON.stringify({ portfolio: true, notice: true, theme: 'light-mode', api: 'https://api.hiro.so' }));
      document.querySelector('html').setAttribute('data-theme', 'light-mode');
      document.querySelector('body').setAttribute('data-theme', 'light-mode');
    };
  }, []);

  return (
    <>
      <div className="display">
        <HeaderComponent />
        <Outlet context={[session]} />
        <FooterComponent session={session} />
      </div>
    </>
  );
}

export default App;