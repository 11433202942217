import React, { useState, useEffect } from "react";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { fetchVaultByID } from "../lib/api.js";
import { checkHour, trunc, formatTokensDisplay } from "../lib/utils.js";

import LoaderComponent from "./LoaderComponent.js";
import ErrorComponent from "./ErrorComponent.js";

import liquidated from "../img/liquidated.png";

export default function VaultComponent() {
  const { id } = useParams();
  const [session] = useOutletContext();
  const navigate = useNavigate();

  const [vault, setVault] = useState();
  const [copyText, setCopyText] = useState("Copy Link");

  useEffect(() => {
    if (!/^\d+$/.test(id)) {
      navigate("/discover");
    } else {
      fetchVaultByID(id).then(res => setVault(res));
      document.title = `Vault #${id} | UWU Analytics`;
    }
  }, [id]);

  useEffect(() => {
    if (vault) {
      if (!vault?.owner) {
        navigate('/');
      };
    };
  }, [vault]);

  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0 || difference < 0) {
      return "#d83434";
    };

    return "#000000";
  };

  function copyLink() {
    setCopyText("Copied")
    setTimeout(() => {
      setCopyText("Copy Link");
    }, 1000);
  };

  return (
    <>
      {vault && session ? <><div className="core animate__animated animate__fadeIn">
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="grid2" style={{ minWidth: "581.18px" }}>
          <div className="vaultHeader">
            <div>
              <div className="vaultHeaderTitle" style={{ justifyContent: "inherit" }}>
                <a href="/" style={{ lineHeight: "0", marginRight: "0.5rem", fontSize: "1.25em", marginBottom: "0.1rem" }}>{`<-`}</a>
                <h1 style={{ marginBottom: "0" }}>Vault #{vault?.id}</h1>
              </div>
              <a onClick={() => { copyLink(); navigator.clipboard.writeText(`https://analytics.uwu.cash/vault/${id}`); }}>{copyText}</a>
            </div>
            <div className="vaultHeaderDivider"></div>
          </div>
          {vault?.liquidated && <div className="pendingTxnHome" style={{ minWidth: "auto", marginBottom: "15px", backgroundColor: "#fdf6f7", borderColor: "#d83434", justifyContent: "unset" }}>
            <img src={liquidated} draggable="false" style={{ width: "40px", height: "40px", padding: "0" }} />
            <div>
              <h1 style={{ color: "#d83434" }}>Vault #{vault?.id} has been liquidated</h1>
              <h2>The collateral in this Vault was sold to repay its debt.</h2>
            </div>
            <a href="https://docs.uwu.cash/uwu-protocol/liquidations" target="_blank" className="smallButton">Learn More</a>
          </div>}
          <div>
            <div className="overview" style={{ marginBottom: "15px", opacity: vault?.liquidated && "0.5", pointerEvents: vault?.liquidated && "none" }}>
              <div className="overviewHeader">
                <h1>Vault Overview</h1>
                <div></div>
              </div>
              <div>
                <div className="overviewGrid">
                  <div>
                    <h2 data-tip="The total value of STX collateral<br>in this Vault">Total Collateral</h2>
                    <h1>${formatTokensDisplay(vault?.collateral * session?.oraclePrice)}</h1>
                    <h3>{formatTokensDisplay(vault?.collateral)} STX</h3>
                  </div>
                  <div>
                    <h2 data-tip="The total amount of UWU debt<br>generated by this Vault">Total Debt</h2>
                    <h1>{formatTokensDisplay(vault?.debt)} UWU</h1>
                  </div>
                  <div>
                    <h2 data-tip="The ratio of total collateral to total<br>debt held in this Vault">Collateral Ratio</h2>
                    <h1 style={{ color: getHealthFactor(((vault?.collateral * session?.oraclePrice) / vault?.debt) * 100) }}>{formatTokensDisplay(((vault?.collateral * session?.oraclePrice) / vault?.debt) * 100)}%</h1>
                  </div>
                </div>
                <div className="gridDivider"></div>
                <div className="overviewGrid">
                  <div>
                    <h2 data-tip="The current STX price reported<br>by the oracle">Oracle Price</h2>
                    <div style={{ display: "flex", padding: "unset" }}>
                      <h1>${formatTokensDisplay(session?.oraclePrice)}</h1>
                      <span className="alertDot" style={{ backgroundColor: checkHour(session?.oracleTimestamp) < 1 ? "#20ba62" : "#d88934", animation: checkHour(session?.oracleTimestamp) < 1 ? "" : "pulse 1s infinite", marginTop: "0.5px", marginLeft: "5px" }} data-tip={checkHour(session?.oracleTimestamp) < 1 ? "The Oracle Price was recently updated" : "The Oracle Price may not reflect<br>the current market price"}></span>
                    </div>
                  </div>
                  <div>
                    <h2 data-tip="The STX price at which this Vault<br>will be liquidated">Liquidation Price</h2>
                    <h1>${formatTokensDisplay((vault?.debt * 1.5) / vault?.collateral)}</h1>
                    <h3>{formatTokensDisplay(((session?.oraclePrice - ((vault?.debt * 1.5) / vault?.collateral)) * 100) / session?.oraclePrice)}% below</h3>
                  </div>
                  <div>
                    <h2 data-tip="The amount of UWU that is currently<br>available to borrow">Available to Borrow</h2>
                    <h1>{formatTokensDisplay(((66.666666 / 100) * (vault?.collateral * session?.oraclePrice)) - vault?.debt, undefined, false)} UWU</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview" style={{ opacity: vault?.liquidated && "0.5", pointerEvents: vault?.liquidated && "none" }}>
              <div className="overviewHeader">
                <h1>Vault Information</h1>
                <div></div>
              </div>
              <div className="overviewGrid2">
                <div>
                  <h2>Vault ID</h2>
                  <h1>{vault?.id}</h1>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2>Vault Owner</h2>
                  <a href={`https://explorer.stacks.co/address/${vault?.owner}?chain=mainnet`} target="_blank" rel="noopener noreferrer">{vault?.bns ? vault?.bns : trunc(vault?.owner)}</a>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2>Date Opened</h2>
                  <h1>{vault?.timestamp}</h1>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2>Liquidation Status</h2>
                  <h1>{vault?.liquidated ? "Liquidated" : "Not Liquidated"}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></> : <LoaderComponent />}
    </>
  );
};