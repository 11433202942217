import { fetchRetry, readOnlyRetry } from "./fetch.js";
import { checkHour, convertMicrotokensToTokens, formatTokens, numberWithCommas } from "./utils.js";
import { StacksMainnet } from "@stacks/network";
import { uintCV } from "@stacks/transactions";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { c32address } from "c32check";

const CONTRACT_ADDRESS = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-factory-v1-1-0";
const NETWORK = new StacksMainnet();

const session = {
  totalValueLocked: 0,
  circulatingSupply: 0,
  totalVaults: 0,
  activeVaults: 0,
  liquidatedVaults: 0,
  stxPrice: 0,
  uwuPrice: 0,
  susdtPrice: 0,
  oraclePrice: 0,
  oracleTimestamp: 0,
  stabilityModule: {
    uwuReserve: 0,
    susdtReserve: 0,
    aeusdcReserve: 0,
    status: false
  },
  stackswapPool: [],
  protocolRevenue: {
    borrow: [],
    swap: []
  }
};

// add fees to session
// fetch on new and on found

export function getApiUri() {
  const storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

  if (storedPreferences) {
    if (storedPreferences.api) {
      return storedPreferences.api;
    } else {
      return "https://api.hiro.so";
    };
  } else {
    return "https://api.hiro.so";
  };
};

export async function fetchSession() {
  const tvl = await fetchTotalValueLocked();
  const stx = await fetchStxPrice();
  const supply = await fetchCirculatingSupply();
  const total = await fetchTotalVaults();
  const active = await fetchActiveVaults();
  const liquidated = await fetchLiquidatedVaults();
  const oraclePrice = await fetchOraclePrice();
  const oracleTimestamp = await fetchOracleTimestamp();
  const stackswapPool = await fetchStackswapPool();
  const borrowRevenue = await fetchBorrowRevenue();
  const swapRevenue = await fetchSwapRevenue();
  const uwuReserve = await fetchUwuBalance("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4", true);
  const susdtReserve = await fetchSusdtBalance("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4", true);
  const aeusdcReserve = await fetchAeusdcBalance("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4", true);
  const susdtPrice = await fetchSusdtPrice();
  const aeusdcPrice = await fetchAeusdcPrice();
  const status = await fetchAPIStatus();

  session.totalValueLocked = formatTokens(tvl / 1000000);
  session.circulatingSupply = formatTokens(supply / 1000000);
  session.totalVaults = total;
  session.activeVaults = active;
  session.liquidatedVaults = liquidated;
  session.stxPrice = formatTokens(stx);
  session.susdtPrice = formatTokens(susdtPrice);
  session.aeusdcPrice = formatTokens(aeusdcPrice);
  session.stabilityModule.uwuReserve = formatTokens(uwuReserve / 1000000);
  session.stabilityModule.susdtReserve = formatTokens(susdtReserve / 100000000);
  session.stabilityModule.aeusdcReserve = formatTokens(aeusdcReserve / 1000000);
  session.oraclePrice = formatTokens(oraclePrice / 1000000, 6);
  session.oracleTimestamp = oracleTimestamp;
  session.stackswapPool = stackswapPool;
  session.protocolRevenue.borrow = borrowRevenue;
  session.protocolRevenue.swap = swapRevenue;
  session.uwuPrice = stackswapPool.price * session?.stxPrice;
  session.status = status;

  return session;
};

const user = {
  address: "",
  bns: "",
  balances: {
    stx: 0,
    uwu: 0,
    xuwu: 0
  },
  vaults: []
};

export async function fetchUser(address) {
  const bns = await fetchBNS(address);
  const stx = await fetchStxBalance(address);
  const vaults = await fetchVaultsByAccount(address);

  user.address = address;
  user.bns = bns;
  user.balances.stx = formatTokens(stx / 1000000);
  user.vaults = vaults;

  return user;
};

export async function fetchVaultsByAccount(address) {
  const vaults = [];

  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-factory-v1-1-0",
    functionName: "get-vaults",
    functionArgs: [principalCV(address)],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  if (fetch.value.list) {
    fetch.value.list.forEach(async (vault) => {
      const v = {
        id: 0,
        owner: "",
        collateral: 0,
        debt: 0,
        height: 0,
        liquidated: false,
        timestamp: 0
      };

      const timestamp = await fetchBlockTimestamp(Number(vault.value.value.data.height.value));

      v.id = Number(vault.value.value.data.id.value);
      v.owner = c32address(22, vault.value.value.data.owner.address.hash160);
      v.collateral = formatTokens(Number(vault.value.value.data.collateral.value) / 1000000);
      v.debt = formatTokens(Number(vault.value.value.data.debt.value) / 1000000);
      v.height = Number(vault.value.value.data.height.value);
      v.liquidated = vault.value.value.data.liquidated.type === 4 ? false : true;
      v.timestamp = new Date(timestamp * 1000).toLocaleDateString("en-US");

      vaults.push(v);
    });
  };

  return vaults;
};

export async function fetchVaultByID(id) {
  const vault = {
    id: 0,
    owner: "",
    bns: "",
    collateral: 0,
    debt: 0,
    height: 0,
    liquidated: false,
    timestamp: 0
  };

  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-factory-v1-1-0",
    functionName: "get-vault",
    functionArgs: [uintCV(id)],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  if (fetch.value.value) {
    const data = fetch.value.value.data;
    const bns = await fetchBNS(c32address(22, data.owner.address.hash160));
    const timestamp = await fetchBlockTimestamp(Number(data.height.value));

    vault.id = Number(data.id.value);
    vault.owner = c32address(22, data.owner.address.hash160);
    vault.bns = bns;
    vault.collateral = formatTokens(Number(data.collateral.value) / 1000000);
    vault.debt = formatTokens(Number(data.debt.value) / 1000000);
    vault.height = Number(data.height.value);
    vault.liquidated = data.liquidated.type === 4 ? false : true;
    vault.timestamp = new Date(timestamp * 1000).toLocaleDateString("en-US");
  };

  return vault;
};

export async function fetchBNS(address) {
  const fetch = await fetchRetry(`${getApiUri()}/v1/addresses/stacks/${address}`)
    .then(res => { return res["names"]["0"]; });

  return fetch;
};

export async function fetchStxBalance(address) {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/${address}/balances`)
    .then(res => { return res["stx"]["balance"]; });

  return fetch;
};

export async function fetchUwuBalance(address, unanchored = false) {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/${address}/balances${unanchored ? "?unanchored=true" : ""}`)
    .then(res => {
      if (res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]) {
        return res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["balance"];
      } else {
        return 0;
      };
    });

  return fetch;
};

export async function fetchSusdtBalance(address, unanchored = false) {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/${address}/balances${unanchored ? "?unanchored=true" : ""}`)
    .then(res => {
      if (res["fungible_tokens"]["SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-susdt::bridged-usdt"]) {
        return res["fungible_tokens"]["SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-susdt::bridged-usdt"]["balance"];
      } else {
        return 0;
      };
    });

  return fetch;
};

export async function fetchAeusdcBalance(address, unanchored = false) {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/${address}/balances${unanchored ? "?unanchored=true" : ""}`)
    .then(res => {
      if (res["fungible_tokens"]["SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K.token-aeusdc::aeUSDC"]) {
        return res["fungible_tokens"]["SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K.token-aeusdc::aeUSDC"]["balance"];
      } else {
        return 0;
      };
    });

  return fetch;
};

export async function fetchStackswapPool() {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp/balances`)
    .then(res => { 
      const pool = [];
      pool.stx = formatTokens(res["stx"]["balance"] / 1000000, 4);
      pool.uwu = formatTokens(res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["balance"] / 1000000, 4);
      pool.price = formatTokens(pool.stx/pool.uwu, 4);

      return pool;
    });

  return fetch;
};

export async function fetchBorrowRevenue() {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.xuwu-fee-claim-v1-1-0/balances`)
    .then(res => {
      const revenue = {
        total: [],
        current: []
      };
      revenue.total.uwu = formatTokens(res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["total_received"] / 1000000, 4);
      revenue.current.uwu = formatTokens(res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["balance"] / 1000000, 4);

      return revenue;
    });

  return fetch;
};

export async function fetchSwapRevenue() {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4/balances`)
    .then(res => {
      const revenue = {
        total: [],
        current: []
      };
      revenue.total.uwu = formatTokens(res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["total_received"] / 1000000, 4);
      revenue.current.uwu = formatTokens(res["fungible_tokens"]["SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0::uwu"]["balance"] / 1000000, 4);

      return revenue;
    });

  return fetch;
};

export async function fetchStxPrice() {
  const fetch = await fetchRetry("https://api.coingecko.com/api/v3/simple/price?ids=blockstack&vs_currencies=usd")
  .then(res => { return res.blockstack.usd; });

  return fetch;
};

export async function fetchSusdtPrice() {
  const fetch = await fetchRetry("https://api.coingecko.com/api/v3/simple/price?ids=alex-wrapped-usdt&vs_currencies=usd")
    .then(res => { return res["alex-wrapped-usdt"].usd; });

  return fetch;
};

export async function fetchAeusdcPrice() {
  const fetch = await fetchRetry("https://api.coingecko.com/api/v3/simple/price?ids=allbridge-bridged-usdc-stacks&vs_currencies=usd")
    .then(res => { return res["allbridge-bridged-usdc-stacks"].usd; });

  return fetch;
};

export async function fetchTotalValueLocked() {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/${CONTRACT_ADDRESS}/stx?unanchored=true`)
    .then(res => { return res.balance; });

  return fetch;
};

export async function fetchCirculatingSupply() {
  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-token-v1-1-0",
    functionName: "get-total-supply",
    functionArgs: [],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  return Number(fetch.value.value);
};

export async function fetchTotalVaults() {
  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-factory-v1-1-0",
    functionName: "get-last-vault-id",
    functionArgs: [],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  return Number(fetch.value.value);
};

export async function fetchActiveVaults() {
  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-factory-v1-1-0",
    functionName: "get-opened-vault-count",
    functionArgs: [],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  return Number(fetch.value.value);
};

export async function fetchLiquidatedVaults() {
  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-factory-v1-1-0",
    functionName: "get-liquidated-vault-count",
    functionArgs: [],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  return Number(fetch.value.value);
};

export async function fetchOraclePrice() {
  const fetch = await readOnlyRetry({
    contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    contractName: "uwu-oracle-proxy-v1-1-0",
    functionName: "get-stx-price",
    functionArgs: [],
    network: NETWORK,
    senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
  });

  return Number(fetch.value.value);
};

export async function fetchOracleTimestamp() {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/address/SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-oracle-v1-1-3/transactions?limit=1`)
    .then(res => { return res.results[0].burn_block_time; });

  return fetch;
};

export async function fetchBlockTimestamp(block) {
  const fetch = await fetchRetry(`${getApiUri()}/extended/v1/block/by_height/${block}`)
    .then(res => { return res.burn_block_time; });

  return fetch;
};

export async function fetchAPIStatus() {
  const fetchAPI = await fetchRetry(`${getApiUri()}/extended/v1/status`)
    .then(res => { return { status: res["status"], height: res["chain_tip"]["block_height"] }; });

  const fetchMempool = await fetchRetry(`${getApiUri()}/extended/v1/tx/mempool/stats`)
    .then(res => { return Object.values(res["tx_type_counts"]).reduce((acc, count) => acc + count, 0); });

  return { fetchAPI, fetchMempool };
};