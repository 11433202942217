export const PopupAnimation = (props) => {
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('popupModal')) {
      props.onClose();
    };
  };

  return (
    <div className={`popupModal ${props.visible}`} onClick={handleClickOutside}>
      <div className={`popupOuter ${props.visible}`}>
        <div className="popupContent">
          {props.children}
        </div>
      </div>
    </div>
  );
};