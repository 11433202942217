import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import gear from "../img/gear.png";

import HeaderMenuPopupComponent from "../popups/HeaderMenuPopupComponent.js";
import APIPopupComponent from "../popups/APIPopupComponent.js";

export default function HeaderComponent() {
  const [headerMenuPopup, setHeaderMenuPopup] = useState(false);
  const [headerMenuVisible, setHeaderMenuVisible] = useState(true);

  const [showApiPopup, setShowAPIPopup] = useState(false);

  function toggleMenu() {
    if (headerMenuPopup) {
      setHeaderMenuVisible(false);
      setTimeout(() => {
        setHeaderMenuPopup(false);
      }, 200);
    } else {
      setHeaderMenuVisible(true);
      setHeaderMenuPopup(true);
    };
  };

  return (
    <>
      {headerMenuPopup && <HeaderMenuPopupComponent show={headerMenuVisible} toggleMenu={() => toggleMenu()} showApi={() => setShowAPIPopup(true)} />}
      {showApiPopup && <APIPopupComponent show={(e) => setShowAPIPopup(e)} />}
      <div>
        <a className="announcementBar" href="http://grafana.uwu.cash">Looking for historical analytics? Click here</a>
        <div className="header">
          <div className="headerLeft">
            <a href="/"><img src="https://cdn.quorumdao.org/uwu-logo-black.png" alt="UWU Protocol Logo" width="55px" draggable="false" /><h1>ANALYTICS</h1></a>
            <ul>
              <li><NavLink to="/" className="navLink">Home</NavLink></li>
              <li><NavLink to="/discover" className="navLink">Discover</NavLink></li>
            </ul>
          </div>
          <div className="headerRight">
            <a className="headerButton headerButtonWhite" style={{ display: "flex", alignItems: "center", padding: "0", borderColor: headerMenuPopup && "#c3c3c3" }} onClick={() => toggleMenu()}><img src={gear} width="15px" style={{ filter: headerMenuPopup && "brightness(0)" }} /></a>
          </div>
        </div>
      </div>
    </>
  );
};