import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from './App.js';
import './styles/globals.css'
import "./styles/main.css";

import HomeComponent from "./components/HomeComponent.js";
import DiscoverComponent from "./components/DiscoverComponent.js";
import VaultComponent from "./components/VaultComponent.js";
import ErrorComponent from "./components/ErrorComponent.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomeComponent />,
      },
      {
        path: "discover",
        element: <DiscoverComponent />,
      },
      {
        path: "vault/:id",
        element: <VaultComponent />,
      },
      {
        path: "*",
        element: <ErrorComponent />,
      }
    ]
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);
