import { callReadOnlyFunction } from "@stacks/transactions";

export function fetchRetry(url, options = {}, retry = 10, timeout = 10000) {
  function err(err) {
    var r = retry - 1;

    if (!r) {
      throw err;
    };

    return promiseWait(timeout).then(() => fetchRetry(url, options, retry, timeout * 2));
  };

  return fetch(url, options).then(res => res.json()).catch(err).catch(err => {
    throw err;
  });
};

export function readOnlyRetry(options = {}, retry = 10, timeout = 10000) {
  function err(err) {
    var r = retry - 1;

    if (!r) {
      throw err;
    };

    return promiseWait(timeout).then(() => readOnlyRetry(options, retry, timeout * 2));
  };

  return callReadOnlyFunction(options).catch(err);
};

export function promiseWait(timeout) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};