import React from 'react';

const SwitchComponent = ({ isActive, onToggle, disabled }) => {

  const handleToggle = () => {
    if (disabled) {
      return;
    };
    onToggle(!isActive);
  };

  return (
    <div className={`switchComponentOuter ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`} onClick={handleToggle}>
      <div className="switchComponent"></div>
    </div>
  );
};

export default SwitchComponent;
