import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DiscoverComponent() {
  document.title = "Discover | UWU Analytics";
  const navigate = useNavigate();
  const [input, setInput] = useState();

  const checkCharacter = (e) => {
    if(e.key === "Backspace" || e.key === "Delete") {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  function visitVault() {
    if(input >= 1) {
      navigate(`/vault/${input}`);
    };
  };

  return (
    <div className="discover">
      <h1>Discover</h1>
      <h2>Explore Vaults and track their performance in real-time.</h2>
      <div>
        <input className="discoverInput" placeholder="Vault ID" onChange={(e) => setInput(e.target.value)} onKeyDown={(e) => checkCharacter(e)} />
        <button onClick={visitVault} style={{ backgroundColor: input ? "#000000" : "#575757", cursor: input ? "pointer" : "default" }}>View Vault</button>
      </div>
    </div>
  );
};