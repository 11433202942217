import React, { useState, useEffect, useRef } from "react";
import { PopupAnimation } from "../lib/animation";
import { fetchRetry } from "../lib/fetch.js";

import checkmark from "../img/checkmark.png";
import loader from "../img/loader.svg";

import SwitchComponent from '../components/SwitchComponent.js';

export default function APIPopupComponent({ show }) {
  const [visibleClass, setVisibleClass] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("hiro");
  const [providerURI, setProviderURI] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [input, setInput] = useState("");
  const [finalInput, setFinalInput] = useState("");
  const [providerError, setProviderError] = useState(false);

  const timeoutIdRef = useRef(null);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);

    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if (storedPreferences.api) {
        if (storedPreferences.api === "https://api.hiro.so") {
          setSelectedProvider("hiro");
        } else if (storedPreferences.api === "https://stacks-blockchain-lb.alexlab.co") {
          setSelectedProvider("alex");
        } else {
          setSelectedProvider("custom");
        };
        setProviderURI(storedPreferences.api);
      } else {
        setProviderURI("https://api.hiro.so");
      };
    };
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  function updatePreferences() {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if (selectedProvider === "hiro") {
        storedPreferences.api = "https://api.hiro.so";
      } else if (selectedProvider === "alex") {
        storedPreferences.api = "https://stacks-blockchain-lb.alexlab.co";
      } else if (selectedProvider === "custom") {
        storedPreferences.api = input;
      };

      localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      window.location.reload();
    };
  };

  const getCurrentProvider = () => {
    let currentProvider = "";

    if (selectedProvider === "hiro") {
      currentProvider = "https://api.hiro.so";
    } else if (selectedProvider === "alex") {
      currentProvider = "https://stacks-blockchain-lb.alexlab.co";
    } else if (selectedProvider === "custom") {
      currentProvider = input.length > 0 ? input : providerURI;

      if (!/^https?:\/\//.test(currentProvider)) {
        currentProvider = `https://${currentProvider}`;
      };
    };

    return currentProvider;
  };

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    let formattedInputValue = inputValue;

    if (!/^https?:\/\//.test(inputValue)) {
      formattedInputValue = `https://${inputValue}`;
    };

    const allowedCharactersRegex = /^[\w-.:/?=&%]*$/;
    const urlRegex = /^(https?:\/\/)([^\s/:?]+)(:[\d]+)?(\/[^\s]*)?$/;

    setInput(inputValue);
    setFinalInput("");
    setProviderError(false);

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    };

    if (inputValue && allowedCharactersRegex.test(formattedInputValue)) {
      timeoutIdRef.current = setTimeout(async () => {
        try {
          const response = await fetch(`${formattedInputValue}/extended/v1/status`);
          const data = response.ok && await response.json();

          if (data && 'status' in data) {
            setFinalInput(formattedInputValue);
            setUnsavedChanges(inputValue.length > 0 && urlRegex.test(formattedInputValue));
          } else {
            setProviderError(true);
          };
        } catch (error) {
          setProviderError(true);
        };
      }, 2000);
    };
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 32) {
      e.preventDefault();
    };
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>API Provider</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody" style={{ textAlign: "left", margin: "2rem" }}>
          <h3 style={{ marginBottom: "1.75rem" }}>An API Provider allows the Web App to communicate with the Stacks blockchain. Choose from a trusted third-party provider, or enter a custom one of your choice.</h3>
          <h1 style={{ fontSize: "0.85rem", marginBottom: "0.5rem" }}>Primary API Provider</h1>
          <div className="apiTable" style={{ marginBottom: "0", borderRadius: "10px 10px 0px 0px" }}>
            <a onClick={() => { setSelectedProvider("hiro"); setUnsavedChanges(true); setInput(""); }} className={`headerButton ${selectedProvider === "hiro" && "active"}`}>Hiro</a>
            <a onClick={() => { setSelectedProvider("alex"); setUnsavedChanges(true); setInput(""); }} className={`headerButton ${selectedProvider === "alex" && "active"}`}>ALEX</a>
            <a onClick={() => { setSelectedProvider("custom"); setUnsavedChanges(false); setInput(""); }} className={`headerButton ${selectedProvider === "custom" && "active"}`}>Custom</a>
          </div>
          <div style={{ border: "1px solid #F0F0F0", padding: "10px", borderTop: "none", borderRadius: "0px 0px 10px 10px", overflow: "hidden" }}>
            <a href={getCurrentProvider() + "/extended/v1/status"} target="_blank" style={{ fontSize: "0.85rem" }}>{getCurrentProvider()}</a>
          </div>
          {selectedProvider === "custom" && <><h1 style={{ fontSize: "0.85rem", marginBottom: "0.5rem", marginTop: "15px" }}>Custom API Provider</h1>
            <span className="borrowInput" style={{ marginBottom: "1rem" }}><input inputmode="url" placeholder="https://..." value={input} onChange={handleInputChange} onKeyPress={handleKeyPress} style={{ fontSize: "1rem", padding: "10px" }} />{(input && !finalInput && !providerError) && <img style={{ padding: "0", backgroundColor: "transparent", margin: "0px 7px 0px 0px", width: "20px", height: "20px" }} src={loader} draggable="false" width="20px" />}</span></>}
          {(selectedProvider === "custom" && providerError) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", marginTop: "-5px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>Unable to connect to the specified API Provider</h1></div>}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginTop: "20px", paddingTop: "20px", borderTop: "1px solid #F0F0F0" }}>
            <div style={{ width: "70%" }}>
              <h1 style={{ fontSize: "0.85rem", marginBottom: "0.5rem" }}>Fallback API Provider</h1>
              <h3 style={{ marginBottom: "0" }}>Auto-switch to another API Provider if the one you're using fails to connect.</h3>
            </div>
            <SwitchComponent disabled />
          </div>
          <div style={{ marginTop: "2rem" }}>
            <a onClick={() => { unsavedChanges && updatePreferences(); }} className="smallButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "2rem", marginRight: "1rem", backgroundColor: unsavedChanges ? "#000000" : "#575757", cursor: unsavedChanges ? "pointer" : "default" }}>Save Changes</a>
            <a onClick={handleClosePopup}>Cancel</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};