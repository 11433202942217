import React from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { checkHour, timeSince, formatTokensDisplay } from "../lib/utils.js";

import LoaderComponent from "./LoaderComponent.js";

export default function HomeComponent() {
  const [session] = useOutletContext();

  document.title = "Home | UWU Analytics";

  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0 || difference < 0) {
      return "#d83434";
    };

    return "#000000";
  };
  
  return (
    <>
      { session ? <div className="core animate__animated animate__fadeIn">
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="top">
          <h1><span>${formatTokensDisplay(session?.circulatingSupply * (session?.stackswapPool.price * session?.oraclePrice))}</span> of borrowing backed by</h1>
          <h1><span>${formatTokensDisplay(session?.totalValueLocked * session?.stxPrice)}</span> of collateral</h1>
        </div>
        <div className="grid">
          <div className='gridColumn'>
            <div className="overview o1">
              <div className="overviewHeader">
                <h1>Overview</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The total amount of STX collateral<br>deposited in all Vaults">Total Collateral</h2>
                  <h1>{formatTokensDisplay(session?.totalValueLocked)} STX</h1>
                </div>
                <div>
                  <h2 data-tip="The total amount of UWU debt<br>generated by all Vaults">Total Debt</h2>
                  <h1>{formatTokensDisplay(session?.circulatingSupply)} UWU</h1>
                </div>
                <div>
                  <h2 data-tip="The ratio of total collateral to<br>total debt held in all Vaults">Total Collateral Ratio</h2>
                  <h1 style={{ color: getHealthFactor(((session?.totalValueLocked * session?.oraclePrice) / session?.circulatingSupply) * 100) }}>{formatTokensDisplay(((session?.totalValueLocked * session?.oraclePrice) / session?.circulatingSupply) * 100)}%</h1>
                </div>
              </div>
            </div>
            <div className="overview o3">
              <div className="overviewHeader">
                <h1>Stability Module</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The dollar value of all assets held in the<br>Stability Module's reserves">Total Deposited</h2>
                  <h1>${formatTokensDisplay((session?.stabilityModule.uwuReserve * session?.uwuPrice) + (session?.stabilityModule.susdtReserve * session?.susdtPrice) + (session?.stabilityModule.aeusdcReserve * session?.aeusdcPrice))}</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of UWU held in the<br>Stability Module's reserves">Total UWU</h2>
                  <h1>{formatTokensDisplay(session?.stabilityModule.uwuReserve)} UWU</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of sUSDT held in the<br>Stability Module's reserves">Total sUSDT</h2>
                  <h1>{formatTokensDisplay(session?.stabilityModule.susdtReserve)} sUSDT</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of aeUSDC held in the<br>Stability Module's reserves">Total aeUSDC</h2>
                  <h1>{formatTokensDisplay(session?.stabilityModule.aeusdcReserve)} aeUSDC</h1>
                </div>
              </div>
            </div>
            <div className="overview o5">
              <div className="overviewHeader">
                <h1>STX-UWU LP (Stackswap)</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The dollar value of all assets held<br>in the liquidity pool">Total Deposited</h2>
                  <h1>${formatTokensDisplay((session?.stackswapPool.stx * session?.stxPrice) + (session?.stackswapPool.uwu * (session?.stackswapPool.price * session?.stxPrice)))}</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of STX held<br>in the liquidity pool">Total STX</h2>
                  <h1>{formatTokensDisplay(session?.stackswapPool.stx)} STX</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of UWU held<br>in the liquidity pool">Total UWU</h2>
                  <h1>{formatTokensDisplay(session?.stackswapPool.uwu)} UWU</h1>
                </div>
              </div>
            </div>
            <div className="overview o7">
              <div className="overviewHeader">
                <h1>Oracle</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The current STX price reported<br>by the oracle">Oracle Price</h2>
                  <div style={{ display: "flex", padding: "unset" }}>
                    <h1>${formatTokensDisplay(session?.oraclePrice)}</h1>
                    <span className="alertDot" style={{ backgroundColor: checkHour(session?.oracleTimestamp) < 1 ? "#20ba62" : "#d88934", animation: checkHour(session?.oracleTimestamp) < 1 ? "" : "pulse 1s infinite", marginTop: "0.5px", marginLeft: "5px" }} data-tip={checkHour(session?.oracleTimestamp) < 1 ? "The Oracle Price was recently updated" : "The Oracle Price may not reflect<br>the current market price"}></span>
                  </div>
                </div>
                <div>
                  <h2 data-tip="The time of the latest oracle update">Last Update</h2>
                  <h1>{timeSince(session?.oracleTimestamp)}</h1>
                </div>
                <div>
                  <h2 data-tip="The time of the next scheduled<br>oracle update">Next Update</h2>
                  <h1>in {60 - new Date().getMinutes()}m</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='gridColumn'>
            <div className="overview o2">
              <div className="overviewHeader">
                <h1>Vaults</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The total number of Vaults opened">Total Vaults</h2>
                  <h1>{session?.totalVaults}</h1>
                </div>
                <div>
                  <h2 data-tip="The number of Vaults that<br>are currently open">Active Vaults</h2>
                  <h1>{session?.activeVaults}</h1>
                </div>
                <div>
                  <h2 data-tip="The number of Vaults that<br>have been liquidated">Liquidated Vaults</h2>
                  <h1>{session?.liquidatedVaults}</h1>
                </div>
              </div>
            </div>
            <div className="overview o4">
              <div className="overviewHeader">
                <h1>UWU Savings Rate</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The dollar value of all deposits held<br>in the UWU Savings Rate">Total Deposited</h2>
                  <h1>-</h1>
                </div>
                <div>
                  <h2 data-tip="The amount of UWU reserved for payouts">Available to Payout</h2>
                  <h1>-</h1>
                </div>
                <div>
                  <h2 data-tip="The current rate earned by depositors">Current Rate</h2>
                  <h1>-</h1>
                </div>
              </div>
            </div>
            <div className="overview o6">
              <div className="overviewHeader">
                <h1>Protocol Fees</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The total amount of revenue<br>generated by the protocol">Total Fees</h2>
                  <h1>{formatTokensDisplay(session?.protocolRevenue.borrow.total.uwu + session?.protocolRevenue.swap.total.uwu - 1100.30)} UWU</h1>
                </div>
                <div>
                  <h2 data-tip="The total amount of revenue<br>generated from borrow fees">Borrow Fees</h2>
                  <h1>{formatTokensDisplay(session?.protocolRevenue.borrow.total.uwu)} UWU</h1>
                </div>
                <div>
                  <h2 data-tip="The total amount of revenue<br>generated from swap fees">Swap Fees</h2>
                  <h1>{formatTokensDisplay(session?.protocolRevenue.swap.total.uwu - 1100.30)} UWU</h1>
                </div>
              </div>
            </div>
            <div className="overview o8">
              <div className="overviewHeader">
                <h1>Market Prices</h1>
                <div></div>
              </div>
              <div className="overviewGrid">
                <div>
                  <h2 data-tip="The market price of STX, as<br>reported by CoinGecko">STX Price</h2>
                  <h1>${formatTokensDisplay(session?.stxPrice)}</h1>
                </div>
                <div>
                  <h2 data-tip="The market price of UWU, derived<br>from the STX-UWU liquidity pool">UWU Price</h2>
                  <h1>${formatTokensDisplay(session?.uwuPrice)}</h1>
                </div>
                <div>
                  <h2 data-tip="The market price of sUSDT, as<br>reported by CoinGecko">sUSDT Price</h2>
                  <h1>${formatTokensDisplay(session?.susdtPrice)}</h1>
                </div>
                <div>
                  <h2 data-tip="The market price of aeUSDC, as<br>reported by CoinGecko">aeUSDC Price</h2>
                  <h1>${formatTokensDisplay(session?.aeusdcPrice)}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <LoaderComponent /> }
    </> 
  );
};